<template>
  <div id="app" class="min-vh-100" :class="appBgClass">
    <Headbar />
    <router-view/>
  </div>
</template>

<script>
import Headbar from '@/components/Headbar.vue'

export default {
  components: {
    Headbar
  },
  data() {
    return {
      appBgClass: null
    }
  },
  created() {
    this.$router.afterEach((to) => {
      this.appBgClass = 'bg-' + to.name;
    });
  }
}
</script>
