<template>
  <div class="headbar-space" v-if="stepSettings[currentStep]">
    <div class="headbar headbar-fixed framing-x-lg d-flex justify-content-center">
      <div v-for="(stepName, step) in stepSettings" class="step" :class="stepClass(step)" :key="step">
        <div class="step-bg"></div>
        <div v-if="step == currentStep && stepGoals[$route.name]" class="step-progress d-flex align-items-center">
          <div class="progress-bar mr-4"><div class="progress-bar-fill" :style="'width: '+ (stepProgress[$route.name] / stepGoals[$route.name]) * 100 +'%'"></div></div>
          <div style="opacity:0.5">{{ stepProgress[$route.name] }}/{{ stepGoals[$route.name] }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import questions from '../data/questions.js'

export default {
  name: 'Headbar',
  data() {
    return {
      currentStep: -1,
      stepSettings: ["BasicInfo1", "BasicInfo2", "Sheet1", "Result1", "Sheet2", "Result2"]
    }
  },
  computed: {
    stepGoals() {
      return {
        BasicInfo1: this.$root.$data.requiredInputs.BasicInfo1.length,
        BasicInfo2: this.$root.$data.requiredInputs.BasicInfo2.length,
        Sheet1: questions('sheet1').length,
        Sheet2: questions('sheet2').length
      };
    },
    stepProgress() {
      let count = 0, values = [], answers = this.$root.$data[this.$route.name];

      if (Array.isArray(answers)) {
        values = answers;
      }
      else {
        for (let column in answers) {
          let columnRequired = this.$root.$data.requiredInputs[this.$route.name].indexOf(column) != -1;
          if (columnRequired) values.push(answers[column]);
        }
      }

      for (let i=0; i<values.length; i++) {
        if (values[i] && values[i] !== '') count++;
      }

      let progress = {};
      progress[this.$route.name] = count;
      return progress;
    }
  },
  created() {
    this.$router.afterEach((to) => {
      this.currentStep = this.stepSettings.indexOf(to.name);
    });
  },
  methods: {
    stepClass(step) {
      return { current: (step == this.currentStep), completed: (step < this.currentStep) };
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.headbar-space {
  font-size: 0.75rem;
  height: calc(14px * 2 + 2em);
}

.headbar {
  background: white;
  padding: 14px 0;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.06);
}

.headbar-fixed {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
}

.step {
  position: relative;
  min-width: 2em;
  height: 2em;
  color: white;
  line-height: 2em;
  text-align: center;
  padding-left: 2em;
}
.step-bg {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1em;
}

.step-bg,
.step:not(:last-child)::before,
.step.current::before {
  background: #D9E6EE;
}
.step.current > .step-bg {
  background: #299BAB;
}
.step.completed > .step-bg,
.step.completed::before {
  background: #74C1CC;
}

.step:not(:last-child) {
  margin-right: .5em;
}
.step:not(:last-child)::before {
  content: '';
  position: absolute;
  left: calc(100% - .25em);
  top: calc(50% - 1px);
  width: 1em;
  height: 2px;
}
.step::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 2em;
  height: inherit;
  border-radius: inherit;
}
.step:first-child::after { content: '1' }
.step:nth-child(2)::after { content: '2' }
.step:nth-child(3)::after { content: '3' }
.step:nth-child(4)::after { content: '4' }
.step:nth-child(5)::after { content: '5' }
.step:nth-child(6)::after { content: '6' }

.step-progress {
  position: relative;
  padding-right: 0.667em;
  font-size: 12px;
}

.progress-bar, .progress-bar-fill {
  vertical-align: center;
  height: 4px;
  border-radius: 2px;
}
.progress-bar {
  width: 3.75rem;
  background-color: rgba(255, 255, 255, 0.3);
}
.progress-bar-fill {
  background-color: white;
}

@media screen and (min-width: 960px) {
  .headbar-space {
    font-size: 1rem;
  }

  .headbar {
    border-bottom-left-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }

  .step:not(:last-child) {
    margin-right: 2.25rem;
  }
  .step:not(:last-child)::before {
    left: calc(100% - .25em);
    top: calc(50% - 1px);
    width: calc(2.25rem + .5em);
    height: 4px;
  }

  .step-progress {
    padding-right: 0.875rem;
  }

  .progress-bar, .progress-bar-fill {
    height: 8px;
    border-radius: 4px;
  }

  .progress-bar {
    width: 12.5rem;
  }
}
</style>
