<template>
  <div class="restart">
    <form @submit="postRestart">
      <div class="framing-x bg-content">
        <article class="article">
          <h1 class="home-title text-primary h1">適性測驗線上版(回測)</h1>
          <p>這是一個回測通知，距離你上次測驗已經有一段時間了</p>
          <p>
            每一個人生時間點，會反映您內心不同的職業價值觀，<br>
            所以透過回測系統，可以檢測您是否正往您當初設定的職業目標邁進，或者現在的職業環境仍舊有些問題。
          </p>
          <p>由於之前您已做過測驗，只要透過以下簡單兩個步驟，就可以完成回測檢視。</p>
          <p>
            <span class="text-primary">輸入專屬測驗序號和Email驗證</span><br>
            <span class="text-primary">填寫測驗題目</span>
          </p>

          <fieldset>
            <label>您Email</label>
            <input type="text" name="email" placeholder="請輸入" class="form-control form-control-blue" v-model="email" required />
          </fieldset>

          <fieldset>
            <label>專屬測驗序號</label>
            <input type="text" name="name" placeholder="請輸入" class="form-control form-control-blue" v-model="customer_sn" required />
          </fieldset>
        </article>
      </div>
      <div class="framing-x framing-y">
        <!--<a class="btn btn-primary w-100 footer-button" @click="postRestart">開始</a>-->
        <button class="btn btn-primary w-100 footer-button">開始</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sales_sn: '',
      email: '',
      customer_sn: ''
    };
  },
  mounted() {
    this.sales_sn = this.$route.params.sales_sn;
  },
  methods: {
    postRestart(e) {
      e.preventDefault();
      this.$root.fetchFromIdostech('aptitude/restart', this.$data)
        .then(response => response.json())
        .then(data => {
          if (data.msg == 'success') {
            this.$root.$data.responsePayload.Home.token = data.payload.token;
            this.$root.$data.BasicInfo1 = {
              name: data.payload.customer.name,
              gender: data.payload.customer.gender,
              privacy: true
            };
            this.$root.$data.BasicInfo2 = data.payload.customer;

            this.$router.push({ name: 'BasicInfo1', params:{ sales_sn:data.payload.sales_sn} });
          }
          else {
            console.error('Error:', data.msg);
            alert(data.msg);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });

      return false;
    }
  }
}
</script>

<style lang="scss" scoped>
.restart {
  background: url(../assets/kv1@2x.png) no-repeat center top;
  background-size: 162.5%;
  padding-top: 38.75%;
}
.home-title {
  margin-bottom: 1.5rem;
  line-height: 1;
}

@media screen and (min-width: 960px) {
  .restart {
    background-size: 100%;
    padding-top: 23.82%
  }
  .home-title {
    margin-bottom: 2rem;
  }
}
</style>
