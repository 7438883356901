<template>
  <div class="home">
    <!--<img id="logo" alt="Vue logo" src="../assets/logo.png">-->
    <div class="framing-x bg-content">
      <article class="article">
        <h1 class="home-title text-primary h1">適性測驗線上版</h1>
        <p>這是一份<span class="text-primary">非常精準的職業適性分析</span></p>
        <p>
          填寫前務必詳閱以下說明事項：<br>
          每個人都想找到適合自己的工作，好讓自己可以做得開心、做的長久；為了協助更多人更了解自己未來最好的發展方向，我們從國外引進了一套，與智商、EQ、學歷和背景都沒有關係的適性測驗，這份有趣的適性測驗在國外已經有數百萬人做過，在國內也有數十萬人做過，非常準確！
        </p>
        <p class="mb-0">請您用直覺的方式在10分鐘內寫完。</p>
      </article>
    </div>
    <div class="framing-x framing-y">
      <a class="btn btn-primary w-100 footer-button" @click="$root.postData">開始</a>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$root.$data.Home.sales_sn = this.$route.params.sales_sn;
    this.$root.$data.Home.origin_sn = this.$route.query.origin_sn
  }
}
</script>

<style lang="scss" scoped>
.home {
  background: url(../assets/kv1@2x.png) no-repeat center top;
  background-size: 162.5%;
  padding-top: 38.75%;
}
.home-title {
  margin-bottom: 1.5rem;
  line-height: 1;
}

@media screen and (min-width: 960px) {
  .home {
    background-size: 100%;
    padding-top: 23.82%
  }
  .home-title {
    margin-bottom: 2rem;
  }
}
</style>
