export default function questions(sheet) {
  const characters = {
    sheet1: [
      ["幽默", "嚴肅"],
      ["與世無爭", "好勝"],
      ["喜歡熱鬧", "享受孤獨"],
      ["淡薄名利", "強烈企圖心"],
      ["希望被肯定", "隨緣"],

      ["穩定就好", "想創業"],
      ["喜好冒險", "喜好保守"],
      ["不喜歡被打擾", "主動交友"],
      ["勤勞型", "清閒型"],
      ["嫉惡如仇", "好相處"],

      ["好動", "好靜"],
      ["冷漠", "熱情"],
      ["慷慨", "節儉"],
      ["不善表達於外", "讚美別人"],
      ["執著", "富彈性"],

      ["不善言詞", "說服力"],
      ["注意形象", "不重外表"],
      ["委曲求全", "敢要求別人"],
      ["自我激勵", "需要鼓勵"],
      ["大而化之", "注意小節"],

      ["謙虛有禮", "率直純真"],
      ["喜歡獨自工作", "領導經驗豐富"],
      ["活潑", "文靜"],
      ["獨善其身", "善解人意"],
      ["合群", "喜好獨行"]
    ],
    sheet2: [
      ["愛說話", "文靜"],
      ["負責", "隨意"],
      ["勇敢", "羞怯"],
      ["專注", "鬆懈"],
      ["活潑", "深思"],

      ["勇於挑戰", "接受現實"],
      ["直接", "拐彎抹角"],
      ["固執己見", "猶豫不決"],
      ["敢於對抗", "樂於支持"],
      ["說得多", "問得多"],

      ["不重形式", "注重形式"],
      ["熱情", "冷酷"],
      ["富幻想性", "注重事實"],
      ["以人為中心", "以事為中心"],
      ["容易激動", "較為冷靜"],

      ["容易親近", "保持距離"],
      ["問無不答", "守口如瓶"],
      ["外向", "內向"],
      ["自然的", "做作的"],
      ["衝動的", "理智的"]
    ]
  }

  let questions = [];
  if (!characters[sheet]) return [];

  for (let i=0; i<characters[sheet].length; i++) {
    let c1 = characters[sheet][i][0], c2 = characters[sheet][i][1];
    questions.push({
      title: '你是個' + c1 + '還是一個' + c2 + '的人呢?*',
      options: [c1 + '(強)', c1 + '(弱)', c2 + '(弱)', c2 + '(強)']
    });
  }
  return questions;
}
