import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue } from 'bootstrap-vue'
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

import './app.scss'

const TESTING = false;

Vue.use(BootstrapVue)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  data() {
    return {
      responsePayload: { Home: {}, BasicInfo2: {}, Sheet1: {}, Sheet2: {} },

      requiredInputs: {
        BasicInfo1: ['name', 'gender', 'privacy'],
        BasicInfo2: ['age', 'location', 'career', 'career_time', 'marriage', 'email']
      },

      // Ansers
      Home: {},
      BasicInfo1: {},
      BasicInfo2: { notify: 'email', age: '', location: '', education: '', career: '', career_time: '', mobile: '' },
      Sheet1: [],
      Sheet2: [],

      postDataSetting: {
        Home: { actionPath: 'aptitude/start' },
        BasicInfo2: { actionPath: 'aptitude/basic-info', dataScope: 'basicInfo' },
        Sheet1: { actionPath: 'aptitude/sheet1', dataScope: 'sheet1' },
        Sheet2: { actionPath: 'aptitude/sheet2', dataScope: 'sheet2' }
      }
    }
  },
  methods: {
    scrollToElement(destEl) {
      if (!destEl) {
        window.scrollTo({ top: document.body.clientHeight, behavior: 'smooth' });
        return;
      }

      let destRect = destEl.getBoundingClientRect();
      let bottomToWindow = (window.innerHeight - destRect.height) * 0.38
      let destElBottom = window.scrollY + destRect.y + destRect.height + bottomToWindow;
      window.scrollTo({
        top: destElBottom - window.innerHeight,
        behavior: 'smooth'
      });
    },
    apiUrl(path) {
      return 'https://api.idostech.com/v1/' + path;
    },
    fetchFromIdostech(path, data, method='post') {
      if (this.responsePayload['Home']) {
        data['token'] = this.responsePayload['Home'].token;
      }

      let uri = this.apiUrl(path);
      let requestOption = {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      };
      return fetch(uri, requestOption);
    },
    postData(e) {
      if (e) e.preventDefault();

      let requestData = {},
          postDataScope = this.postDataSetting[this.$route.name].dataScope;
      if (postDataScope) {
        requestData[postDataScope] = this.$data[this.$route.name];
      } else {
        requestData = this.$data[this.$route.name];
      }

      this.fetchFromIdostech(this.postDataSetting[this.$route.name].actionPath, requestData)
        .then(response => response.json())
        .then(data => {
          if (data.msg == 'success') {
            console.log('Success:', data);
            this.responsePayload[router.currentRoute.name] = data.payload;
            router.push({ name: router.currentRoute.meta.next });
          }
          else {
            console.error('Error:', data.msg);
            alert(data.msg);
            if (TESTING) router.push({ name: router.currentRoute.meta.next });
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          if (TESTING) router.push({ name: router.currentRoute.meta.next });
        });

      return false;
    },
    reportFormValidity(form) {
      if (form.reportValidity()) { return true; }

      let invalidEl = form.querySelector('input:invalid:focus, select:invalid:focus');
      console.log(invalidEl);
      if (!invalidEl) {
        invalidEl = form.querySelector('input:invalid, select:invalid');
        invalidEl.focus();
      }
      this.scrollToElement(invalidEl);

      return false;
    }
  }
}).$mount('#app')
