import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Restart from '../views/Restart.vue'

Vue.use(VueRouter)
Vue.mixin({
  beforeRouteEnter: (to, from, next) => {
    if (!(to.name == 'Home' || to.name == 'Restart') && from.name == null) {
      return next({ name: 'Home', params: to.params });
    }
    if (to.meta.completed == true) return next(false);

    next();
  },
  beforeRouteLeave(to, from, next) {
    from.meta.completed = true;
    next();
  }
})

const routes = [
  {
    path: '/s/restart',
    name: 'Restart',
    component: Restart,
    meta: { next: 'BasicInfo1' }
  },
  {
    path: '/',
    redirect: to => {
      return '/s/' + to.query.sales_sn;
    }
  },
  {
    path: '/s/:sales_sn',
    name: 'Home',
    component: Home,
    meta: { next: 'BasicInfo1' }
  },
  {
    path: '/admin',
    name: 'Admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue')
  },
  {
    path: '/s/:sales_sn/basic_info1',
    name: 'BasicInfo1',
    component: () => import(/* webpackChunkName: "basic_info1" */ '../views/BasicInfo1.vue'),
    meta: { next: 'BasicInfo2' }
  },
  {
    path: '/s/:sales_sn/basic_info',
    name: 'BasicInfo2',
    component: () => import(/* webpackChunkName: "basic_info2" */ '../views/BasicInfo2.vue'),
    meta: { next: 'Sheet1' }
  },
  {
    path: '/s/:sales_sn/sheet1',
    name: 'Sheet1',
    component: () => import(/* webpackChunkName: "sheet1" */ '../views/Sheet1.vue'),
    meta: { next: 'Result1' }
  },
  {
    path: '/s/:sales_sn/result1',
    name: 'Result1',
    component: () => import(/* webpackChunkName: "result1" */ '../views/Result1.vue'),
    meta: { next: 'Sheet2' }
  },
  {
    path: '/s/:sales_sn/sheet2',
    name: 'Sheet2',
    component: () => import(/* webpackChunkName: "sheet2" */ '../views/Sheet2.vue'),
    meta: { next: 'Result2' }
  },
  {
    path: '/s/:sales_sn/result2',
    name: 'Result2',
    component: () => import(/* webpackChunkName: "result2" */ '../views/Result2.vue')
  },
  {
    path: '/s/:sales_sn/end',
    name: 'End',
    component: () => import(/* webpackChunkName: "end" */ '../views/End.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
